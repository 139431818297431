<template>
  <div>
    <section
      id="services"
      class="w-full py-0 pb-0 sm:pb-0 bg-gradient-to-b from-cblack via-cblack to-[#335660] text-white"
    >
      <div class="mx-auto max-w-6xl px-4 sm:px-0 py-16 sm:py-20">
        <div class="mb-8 sm:mb-16">
          <p
            class="text-center text-xs font-semibold tracking-wide uppercase text-cgreen"
          >
            Produits & services
          </p>
          <h2 class="text-center text-2xl font-black sm:text-4xl mt-7 sm:leading-tight">
            Nos produits et services
          </h2>
        </div>
        <div class="flex flex-wrap -m-16 md:-m-3">
          <div class="w-full md:w-1/3 pb-8 sm:pb-16 p-16 md:p-3">
            <div
              class="pt-9 sm:pt-11 text-center bg-cgreen bg-opacity-10 rounded-md overflow-hidden"
            >
              <div class="px-10 relative">
                <div class="absolute bottom-0 sm:bottom-0 left-8 opacity-5 -z-0">
                  <span class="text-[140px] sm:text-[150px] font-black">
                    <img
                      src="/assets/img/content/3.png"
                      alt=""
                      class="w-[50px] sm:w-[50px] mr-4 -z-0"
                    />
                  </span>
                </div>
                <h3 class="mb-3 text-2xl font-bold font-heading leading-normal">
                  Amani Logistics
                </h3>
                <p
                  class="mb-8 sm:mb-12 text-base text-cgray-300 max-w-[200px] flex justify-center mx-auto"
                >
                  Fret routier et sercives logistiques intègres
                </p>
                <div class="flex items-center justify-center my-8 flex-col space-y-4">
                  <router-link
                    to="/services/logistics"
                    class="z-40 flex items-center w-auto mx-auto text-base leading-tight text-center text-cyellow font-bold hover:underline sm:mx-0"
                  >
                    <span>En savoir plus</span>
                    <svg
                      class="w-4 transform -rotate-45 h-4 ml-2 opacity-70"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </router-link>
                  <router-link
                    to="/contact"
                    class="flex items-center w-auto mx-auto text-md leading-tight text-center text-cgreen font-semibold hover:underline sm:mx-0"
                  >
                    <span>Nous contacter</span>
                    <svg
                      class="w-4 h-4 ml-2 opacity-70"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                      ></path>
                    </svg>
                  </router-link>
                </div>
              </div>

              <div class="mt-10 sm:mt-12">
                <img
                  class="mx-auto h-72 object-cover transform hover:translate-y-3 transition ease-in-out duration-1000"
                  src="/assets/img/content/vr2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-16 py-0 sm:px-16 sm:py-16 md:p-3">
            <div
              class="pt-9 sm:pt-11 text-center bg-cgreen bg-opacity-10 rounded-md overflow-hidden"
            >
              <div class="px-10 relative">
                <div class="absolute bottom-0 sm:bottom-0 left-8 opacity-5 -z-0">
                  <span class="text-[140px] sm:text-[220px] font-black">
                    <img
                      src="/assets/img/content/2.png"
                      alt=""
                      class="w-[80px] sm:w-[80px] mr-4"
                    />
                  </span>
                </div>
                <h3 class="mb-3 text-2xl font-bold font-heading leading-normal">
                  Aqua GL
                </h3>
                <p
                  class="mb-8 sm:mb-12 text-base text-cgray-300 max-w-[200px] flex justify-center mx-auto"
                >
                  Filtration naturelle de l'eau de bonne qualité
                </p>
                <div class="flex items-center justify-center my-8 flex-col space-y-4">
                  <router-link
                    to="/services/aquagl"
                    class="z-40 flex items-center w-auto mx-auto text-base leading-tight text-center text-cyellow font-bold hover:underline sm:mx-0"
                  >
                    <span>En savoir plus</span>
                    <svg
                      class="w-4 transform -rotate-45 h-4 ml-2 opacity-70"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </router-link>
                  <router-link
                    to="/contact"
                    class="flex items-center w-auto mx-auto text-md leading-tight text-center text-cgreen font-semibold hover:underline sm:mx-0"
                  >
                    <span>Nous contacter</span>
                    <svg
                      class="w-4 h-4 ml-2 opacity-70"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                      ></path>
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="mt-10 sm:mt-12">
                <img
                  class="mx-auto h-72 object-cover transform hover:translate-y-3 transition ease-in-out duration-1000"
                  src="/assets/img/content/5.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 pt-8 sm:pt-16 p-16 md:p-3">
            <div
              class="pt-9 sm:pt-11 text-center bg-cgreen bg-opacity-10 rounded-md overflow-hidden"
            >
              <div class="px-10 relative">
                <div class="absolute bottom-0 sm:bottom-0 left-8 opacity-5 -z-0">
                  <span class="text-[140px] sm:text-[150px] font-black">
                    <img
                      src="/assets/img/content/3.png"
                      alt=""
                      class="w-[50px] sm:w-[50px] mr-4 -z-0"
                    />
                  </span>
                </div>
                <h3 class="mb-3 text-2xl font-bold font-heading leading-normal">
                  Service Immobilier
                </h3>
                <p
                  class="mb-8 sm:mb-12 text-base text-cgray-300 max-w-[250px] flex justify-center mx-auto"
                >
                  Une agence de proximité, experte et reconnue.
                </p>
                <div class="flex items-center justify-center my-8 flex-col space-y-4">
                  <router-link
                    to="/services/immobilier"
                    class="z-40 flex items-center w-auto mx-auto text-base leading-tight text-center text-cyellow font-bold hover:underline sm:mx-0"
                  >
                    <span>En savoir plus</span>
                    <svg
                      class="w-4 transform -rotate-45 h-4 ml-2 opacity-70"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </router-link>
                  <router-link
                    to="/contact"
                    class="flex items-center w-auto mx-auto text-md leading-tight text-center text-cgreen font-semibold hover:underline sm:mx-0"
                  >
                    <span>Nous contacter</span>
                    <svg
                      class="w-4 h-4 ml-2 opacity-70"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                      ></path>
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="mt-10 sm:mt-12">
                <img
                  class="mx-auto h-72 object-cover transform hover:translate-y-3 transition ease-in-out duration-1000"
                  src="/assets/img/content/6.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HomeS3",
  setup() {},
};
</script>
